<template>
  <div>
    <vx-card>
      <h1 class="mb-10">إضافة عرض</h1>

      <div class="vx-row">
        <div class="vx-col sm:w-1/4 w-1/3 mb-3 flex">
          <vs-input
            data-vv-as="إسم العرض"
            v-validate="'required|min:8'"
            type="string"
            class="w-full"
            name="name"
            label-placeholder="إسم العرض"
            v-model="offer.name"
          />
        </div>
        <div class="vx-col sm:w-1/4 w-1/3 mb-3 flex">
          <vs-input
            data-vv-as="تاريخ الإنتهاء"
            type="date"
            class="w-full"
            name="expire"
            v-validate="'required'"
            label="تاريخ الإنتهاء"
            v-model="offer.expire_date"
          />
        </div>
        <div class="vx-col sm:w-1/6 w-1/3 mb-3 flex">
          <vs-input
            data-vv-as="قيمة التخفيض"
            v-validate="
              offer.is_percentage
                ? 'required|numeric|max:2|min:0'
                : 'required|numeric|min:0'
            "
            type="string"
            class="w-full"
            name="percentage"
            label-placeholder="التخفيض"
            v-model="offer.value"
          />
        </div>
        <vs-checkbox v-model="offer.is_percentage">نسبة مئوية</vs-checkbox>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/4 w-1/3 mb-3 flex">
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col sm:w-1/4 w-1/3 mb-3 flex">
          <span class="text-danger text-sm" v-show="errors.has('expire')">{{
            errors.first("expire")
          }}</span>
        </div>
        <div class="vx-col sm:w-1/6 w-1/3 mb-3 flex">
          <span class="text-danger text-sm" v-show="errors.has('percentage')">{{
            errors.first("percentage")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-table :data="tableData">
            <template slot="thead">
              <vs-th>SKU رقم</vs-th>
              <vs-th>إسم المنتج</vs-th>
              <vs-th>العمليات</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].sku">
                  {{ data[indextr].sku }}
                </vs-td>
                <vs-td :data="data[indextr].title">
                  {{ data[indextr].title }}
                </vs-td>
                <vs-td>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="deleteProduct(indextr)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-row mt-2 w-full align-items-center">
          <div class="vx-col my-3 sm:w-1/4 w-full mb-2 flex">
            <label class="vs-input--label"> اختر رقم sku</label>
            <v-select
              name="sku"
              class="w-full"
              data-vv-as="sku"
              data-vv-scope="order-items"
              label="sku"
              :options="product_skus"
              v-model="selected_product"
              :tabindex="4"
              :filterable="true"
            ></v-select>
          </div>
          <div class="vx-col my-3 sm:w-1/6 w-full mb-2 flex self-end">
            <vs-button
              class="mx-1"
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              @click="addProduct"
            ></vs-button>
          </div>
        </div>
      </div>
      <div class="vx-row mt-8">
        <div class="vx-col sm:w-full w-full mt-10 text-right">
          <vs-button
            class="mr-5 text-lg py-4"
            @click="$router.go(-1)"
            color="warning"
            type="border"
            >إلغاء</vs-button
          ><vs-button
            class="mr-5 text-lg px-16 py-4"
            @click="newOffer"
            color="success"
            type="filled"
            >إضافة عرض</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moduleOffer from "@/store/offers/moduleOffer.js";

export default {
  data() {
    return {
      offer: {
        name: "",
        is_percentage: 0,
        value: "",
        expire_date: "",
        products: [],
      },

      tableData: [], //the products that will be added

      selected_product: {}, //the product that is going to be added

      product_skus: [], //a list of products that are being selected
    };
  },
  components: {
    vSelect,
  },
  methods: {
    chooseProduct() {},
    addProduct() {
      let duplicated = this.tableData.find(
        (item) => item.sku == this.selected_product.sku
      );
      if (duplicated) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "المنتج موجود مسبقا",
          color: "danger",
        });
        return false;
      }
      this.tableData.push(this.selected_product); //adding the selected product to the table
    },
    deleteProduct(index) {
      this.tableData.splice(index, 1);
    },
    async newOffer() {
      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });
      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      if (this.tableData.length <= 0) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى إضافة منتج علي الأقل",
          color: "danger",
        });
        return false;
      }
      this.offer.products = this.tableData;
      var bodyFormData = new FormData();
      bodyFormData.set("name", this.offer.name);
      bodyFormData.set("is_percentage", this.offer.is_percentage ? 1 : 0);
      bodyFormData.set("value", this.offer.value);
      bodyFormData.set("expire_date", this.offer.expire_date);
      //sending a group of products
      for (var i = 0; i < this.offer.products.length; i++) {
        bodyFormData.append(
          "products[" + i + "]",
          JSON.stringify(this.offer.products[i].id)
        );
      }

      this.$store
        .dispatch("offer/createOffer", bodyFormData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت إضافة العرض بنجاح",
            color: "success",
          });
          this.$router.push({ name: "offers" });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
    getSKUs() {
      this.$store.dispatch("offer/productSKUs").then((response) => {
        this.product_skus = response;
      });
    },
  },
  created() {
    if (!moduleOffer.isRegistered) {
      this.$store.registerModule("offer", moduleOffer);
      moduleOffer.isRegistered = true;
    }
    this.getSKUs();
  },
};
</script>
